@import '../../variables.scss';

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: $titleLarge;
	background-color: $backgroundDark;
	background-image: $backgroundImage;
  color: white;
}

.testResults {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  // height: 100%;
  // background-color: $backgroundLight;
  // color: black;
	background-color: $backgroundDark;
	background-image: $backgroundImage;
  color: white;

  font-size: $subheader;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  .mainContainer {
    box-sizing: border-box;
    padding: 5vh 5vw;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: $maxContentWidth;
  }
  .header {
    font-size: $titleLarge;
    color: $testiveOrange;
    font-weight: $extraBold;
    padding-bottom: 40px;
    text-align: center;
  }
  .footer {
    padding-top: 25px;
    a {
      color: $primaryLight;
    }
  }
  .subHeader {
    text-align: center;
    font-size: $subheader;
    span {
      color: $testiveOrange;
      font-weight: $extraBold;
    }
  }
  .infoHeader {
    font-weight: $semibold;
    margin-bottom: $defaultPadding;
  }
  .breakdown{
    padding: 10px 0;
  }
  .data {
    display: flex;
    flex-direction: column;
  }
  .dataEntry {
    padding: 5px 0 15px 0;
  }
  .dataHeader {
    font-weight: bold;
    font-size: $header;
    padding-bottom: 10px;
  }
  .dataText {
    padding-bottom: 15px;
  }
}