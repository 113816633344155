@import '../../variables.scss';

.welcomeScreen {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: $backgroundLight;
  color: black;
  font-size: $subheader;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  .mainContainer {
    box-sizing: border-box;
    padding: 10vh 0 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid grayExtraLight;
    border-radius: $borderRadius;
    max-width: $maxContentWidth;
    @media screen and (max-width:$viewPhone) {
      flex: 1;
      width: 100%;
      border: none;
    }
  }

  .instructions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin: 3vh 5vw 0 5vw;
    line-height: 1.4em;
  }
  
  .list {
    .listTitle { 
      font-weight: $bold;
    }
    display: flex;
    flex-direction: column;
    line-height: 1.4em;
    align-content: center;
    padding-top: $defaultPadding;
    margin: $defaultPadding auto;
    max-width: 600px;
    span {
      font-size: $smallHeader;
    }
  }
  .buttonContainer {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
  }
}
.checkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: $smallHeader;
  width: 100%;
}
