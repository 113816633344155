@import '../../variables.scss';

.testSectionDirections {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $grayExtraDark;
  background-image: $backgroundImage;
  opacity: .98;
  color: $fontColor;
  font-size: $subheader;
  line-height: 2em;
  font-weight: $semibold;
  text-align: center;

  .mainContainer {
    background: $grayExtraDark;
    box-sizing: border-box;
    border: 1px solid $primaryExtraLight;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 800px;
    padding: 10vh 5vw 5vh;
    overflow: scroll;

    @media screen and (max-width:$viewPhone) {
      flex: 1;
      width: 100%;
      height: 100%;
      border: none;
      padding: 2vh 2vw;
    }
  }
   
  .header {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
    .subject {
      font-weight: $bold;
      font-size: $title;
      color: $primary;
      text-transform: uppercase;
    }
    .details {
      font-style: italic;
      text-transform: capitalize;
      font-size: $subheader;
    }
  }
}