@import '../../variables.scss';

.fullBar {
  max-width: 350px;
  display: flex;
}

.progressBar {
  height: 25px;
  width: 50%;
  border: 2px solid white;
  margin: 2px 0 10px 0;
  text-align: center;
  position: relative;
}
.labelL { 
  z-index: 1;
  position: absolute;
  color: white;
  opacity: 0.5;
  left: 38%;
}

.fillerL {
  background: $errorColor;
  height: 100%;
  float: right;
}

.labelR { 
  z-index: 1;
  position: absolute;
  color: white;
  opacity: 0.5;
  right: 38%;
}

.fillerR {
  background: $secondaryLight;
  height: 100%;
}
