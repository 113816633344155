@import '../../variables.scss';

.questionNavigator {
  box-sizing: border-box;
  background-color: $backgroundDark;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 2px 5px;
}
.questionNavigator > Button {
  flex: 1;
  font-size: $standard;
}