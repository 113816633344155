@import '../../variables.scss';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $headerColor;
  padding: 2px 10px;
  border-bottom: 1px solid $primaryExtraLight;
  color: white;
  box-sizing: border-box;
  font-size: $subheader;
  .logo {
    box-sizing: border-box;
    display: flex;
    margin-right: 1vw;
  }
  img {
    width: 50px;
    height: 50px;
  }
  .headerBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    font-size: $standard;
    .title {
      margin: auto 0;
      padding: 3px;
      font-size: $subheader;
      font-weight: $bold;
      text-transform: uppercase;
    }
    .title2 {
      font-size: $small;
      font-weight: $regular;
    }
    .timer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 3px;
    }
  }  
  .button {
    background: $primary;
    color: white;
    font-size: $small;
    border: 1px solid white;
    margin-left: 2vw;
    padding: 6px 10px;
    border-radius: $borderRadius;
    &:hover {
        cursor: pointer;
        color: $primary;
        background: $primaryLight;
        border-color: $primaryLight;
    }
  }
}