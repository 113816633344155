@import '../../variables.scss';

.timer {
  border-radius: $borderRadius;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-evenly;
  font-size: $subheader;
  text-transform: uppercase;
  font-weight: $bold;
  text-align: center;

  .text {
    margin: auto 2px;
  }
  span {
    font-size: $small;
    text-transform: lowercase;
  }
  .number {
    margin: auto 2px; 
    // font-family: 'Courier New', Courier, monospace; 
  }
  .firstWarning {
    color: $timerFirstWarning;
  }
  .secondWarning {
    color: $timerSecondWarning;
  }
  .finalWarning {
    color: $timerFinalWarning;
    animation: blinker 1s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}

