@import '../../variables.scss';

.appTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
  img {
    width: 100%;
    max-width: $maxContentWidth;
  }
}