@import "../../variables.scss";

.questionSet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .questionList > div {
    flex-basis: auto;
    padding: $defaultPadding 0;
    border-bottom: 1px dotted $grayDark;
  }
 
}
.questionSet > Button {
  flex: 1;
  padding: $defaultPadding 0;
}
