@import '../../variables.scss';

.answer {
	box-sizing: border-box;
  color: white;
  background: $primary;
	border: 1px solid white;
	border-radius: $borderRadius;
	margin: 2px;
	padding: 8px;
	display: flex;
	flex-direction: row;
	font-weight: $semibold;	
	font-size: $standard;


	.answerPrefix {
		margin-right: 10px;
	}

	&:hover {
		cursor: pointer;
	}
	
	&.selected {
		background: white;
		color: black;
		border-color: $primary;
		margin: 1px;
		font-weight: $bold;
	}
}