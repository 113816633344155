@import '../../variables.scss';

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: $titleLarge;
	background-color: $backgroundDark;
	background-image: $backgroundImage;
  color: white;
}
.results {
  min-height: 120vh;
  padding: 30vh 15% 0 15%;
	background-color: $backgroundDark;
	background-image: $backgroundImage;
  color: white;
}
.header {
  font-size: $titleLarge;
  color: $testiveOrange;
  font-weight: $extraBold;
  padding-bottom: 40px;
  text-align: center;
}
.subHeader {
  text-align: center;
  font-size: $subheader;
  span {
    color: $testiveOrange;
    font-weight: $extraBold;
  }
}
.button {
  padding: 15vh 0 5vh 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.breakdown{
  padding: 10px 0;
}
.data {
  display: flex;
  flex-direction: column;
}
.dataEntry {
  padding: 15px 0;
}
.dataHeader {
  font-weight: bold;
  font-size: $header;
  padding-bottom: 10px;
}
.dataText {

}