@import "../../variables.scss";

.question {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  font-size: $standard;
  color: $fontColor;
  padding: 1vmin;
  .questionBody {
    display: flex;
    flex-direction: row;
    padding: 1vmin;
    margin-bottom: 1vmin;
    .questionNumber {
      padding-right: 1vmin;
      font-weight: $semibold;
    }
  }
  .answerInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input {
      width: 50%;
      text-align: center;
      font-size: 3vmin;
      padding: 1vmin;
      font-family: 'Courier New', Courier, monospace;
      letter-spacing: 1em;
      border: 1px solid $grayLight;
      border-radius: $borderRadius;
      color: black;
    }
  }
}

