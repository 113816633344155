@import '../../variables.scss';

.testBreak {
  display: flex;
	flex-direction: column;
	box-sizing: border-box;
	color: $fontColor;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: $backgroundDark;
  background-image: $backgroundImage;
  
  .mainContainer {
    background: $grayExtraDark;
    box-sizing: border-box;
    border: 1px solid $primaryExtraLight;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50vw;
    max-width: $maxContentWidth;
    padding: 10vh 5vw 5vh;
    overflow: scroll;
 
    @media screen and (max-width:$viewPhone) {
			flex: 1;
      width: 100%;
      height: 100%;
      border: none;
      padding: 2vh 2vw;
      justify-content: center;
		}
  }
  .timer {
    font-size: $title;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-weight: $bold;
    font-size: $title;
    color: $primary;
    text-transform: uppercase;
    margin-bottom: 2vh;
  } 
  .subTitle {
    display: flex;
    flex-direction: row;
    font-size: $standard;
    text-transform: lowercase;
    align-items: center;
  }
  .buttonContainer {
    display: flex;
    align-content: center;
    margin: $defaultPadding 0;	
  }

}