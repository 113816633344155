@import "../../variables.scss";

.passageQuestionSet {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  max-height: 100%;
  border-bottom: 1px solid $backgroundDark;
  .passageViewer {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    flex: 1 1 auto;
  }
  .passageQuestions
  {
    background: $backgroundDark;
    border-left: 1px solid $grayDark;
    padding: 5px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    flex: 0 0 40vw;
    min-width: 300px;

  }

  @media screen and (max-width:$viewPhone) {
    flex-direction: column;
    .passageViewer {
      flex: 1;
    }
    .passageQuestions {
      // g s b
      flex: 0 0 auto;
      border-top: 1px solid $grayDark;
      border-left: none;
      padding: 5px 0 0;
    }
  }
}
